<footer>
    <div class="holderFooter">
        <div class="footerIndividual">
            <a routerLink="/" class="logo"><img src="../assets/img/logo.png" alt="Sentio Logo"
                    aria-label="Click here to navigate back to the home page" /></a>
        </div>
        <div class="footerIndividual">
            <p>© Sentio Capital Management (Pty) Ltd {{ anio }}.<br /> <br /><a
                    href="../../../assets/sentio_disclaimer.pdf" target="_blank">Disclaimer</a></p>
        </div>
        <div class="footerIndividual">
            <p [innerHTML]="footer"></p>
        </div>
        <div class="footerIndividual lastFooter">
            <a target="_blank" class="sociLogo" href="https://www.instagram.com/sentio_capital/?hl=en"><img
                    src="../../../assets/img/social/Insta.png" alt="Instagram Logo"
                    aria-label="Click here to navigate to our Instagram page" /></a>
            <a target="_blank" class="sociLogo" href="https://twitter.com/sentiocapital?lang=en "><img
                    src="../../../assets/img/social/Twitter.png" alt="Twitter Logo"
                    aria-label="Click here to navigate to our Twitter page" /></a>
            <a target="_blank" class="sociLogo" href="https://www.linkedin.com/company/sentio-capital-management/"><img
                    src="../../../assets/img/social/LinkedIn.png" alt="LinkedIn Logo"
                    aria-label="Click here to navigate to our LinkedIn page" /></a>
            <a target="_blank" class="sociLogo" href="https://www.youtube.com/channel/UC_MZ06_wZX-J05WP_Q33kPA/videos"><img
                    src="../../../assets/img/social/youtube.png" alt="Youtube Logo"
                    aria-label="Click here to navigate to our Youtube channel" /></a>            
        </div>
    </div>
</footer>
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';


@Component({
  selector: 'app-invest',
  templateUrl: './invest.component.html',
  styleUrls: ['./invest.component.css']
})
export class InvestComponent implements OnInit {
  intro: Object;
  desc: Object;
  constructor(private data: DataService) { }

  ngOnInit(): void {
    this.data.getInvestPara().subscribe(data => {
     this.intro =data['data'][0].title;
     this.desc =data['data'][0].description;
    });
  }

}

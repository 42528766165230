<app-heading-holder headerTitle="Factsheets"></app-heading-holder>
<div class="indivContainer"><p>Some of Sentio's products are hosted by <b>Sanlam Collective Investments (SCI)</b>, so certain factsheets will incorporate Sanlam branding. However, all investment decisions relating to such funds are undertaken solely by Sentio.</p></div>
<div class="downloadSection">
    <div *ngFor="let item of factsheetHold">
        <div class="downloadHolder" *ngIf="item.storage_url">
            <p class="textDownload">{{ item.name }}</p>            
            <a class="downloadLink" href="{{ item.storage_url }}" download aria-label="Download the factsheet here" target="_blank">
                <i class="fa fa-download"></i>
                <p>Download PDF</p>
            </a>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import {animate, keyframes, query, stagger,style,transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-institutional',
  templateUrl: './institutional.component.html',
  styleUrls: ['./institutional.component.css']
})
export class InstitutionalComponent implements OnInit {

  title = '';
  desc = '';
  header;
  status:boolean= false;
  nameSelected= "All";
  shakeIt = false;

  public ProductHeader: any = [
    {
      name: 'All'
    },
    {
      name: 'Multi-Asset'
    },
    {
      name: 'Fixed income'
    },
    {
      name: 'General Equity'
    },
    {
      name: 'Hedge Fund'
    },
    {
      name: 'Equity'
    },
    {
      name: 'Fixed Income'
    },
    {
      name: 'Institutional'
    },
    {
      name: 'Retail'
    },
    {
      name: "Shari'ah"
    }];

  public ProductDetails: object = [];
  intro: Object;
  ProductsBU: Object;
  ProductHeaderBU: object;
  constructor(private data: DataService, private listProducts: DataService, private listNames: DataService) {
    this.getProducts();
  }
  getProducts() {
    return this.ProductHeader;
  }


  ngOnInit(): void {

    this.data.getInvestInstPara().subscribe(data => {
      this.intro = data["data"][0].description;
    });

    this.listProducts.getInstitutionalProducts().subscribe(listProducts => {
      this.ProductsBU = listProducts['data'];
      this.ProductDetails= listProducts['data']; 
    });

    this.listNames.getInvestProduct().subscribe(listNames => {
      this.ProductHeaderBU = listNames['data'];
    });
  }

  clickEvent(){
    this.status = !this.status;       
}

filterItem(name){
  
  this.status = !this.status;  
  if (name == "Shari"){
    name = "Shari'ah";
  }
  this.nameSelected = name; 

  var count = 0;
  this.ProductDetails = [];
  if (name == "All"){
    this.ProductDetails = this.ProductsBU;
  }else{
    for (var k = 0; k < this.objectLength(this.ProductsBU); k++) {
     if (this.ProductsBU[k].investment_type.includes(name)) {
        this.ProductDetails[count] = this.ProductsBU[k];
        count++;
      }
    }
  }

  

  this.shakeDialog();

  return this.ProductDetails;
  

}

shakeDialog() {
  this.shakeIt = true;
  setTimeout((arg) => {
    this.shakeIt = false;
  },
  2000);
}
  SearchProduct(name: string) {
    var count = 0;
    this.ProductDetails = [];
    if (name == "All"){
      this.ProductDetails = this.ProductsBU;
    }else{
      for (var k = 0; k < this.objectLength(this.ProductsBU); k++) {
        if (this.ProductsBU[k].investment_type.includes(name)) {
          this.ProductDetails[count] = this.ProductsBU[k];
          count++;
        }
      }
    }

    return this.ProductDetails;
  }

  objectLength(obj) {
    var result = 0;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        result++;
      }
    }
    return result;
  }
}


<app-main-banner headerTitle="Responsible Investing" showBGDark="showBGDark" subMainTitle="{{introSubP}}" subTitle="{{introP}}" [styles]="{backgroundColor: '#9E002F'}"></app-main-banner>

<div class="miniHeader">
    <ul class="miniHeaderDesk">
        <li><a routerLink="/responsible-investing/proxy-voting" routerLinkActive="active-link" aria-label="Further details on our proxy voting results">Proxy Voting Results</a></li>
        <li><a routerLink="/responsible-investing/governance" routerLinkActive="active-link" aria-label="Further details on our governance procedures">Governance</a></li>
        <li><a routerLink="/responsible-investing/shariah" routerLinkActive="active-link" aria-label="Further details on our shariah products">Shari'ah</a></li>
        <li class="active"><a routerLink="/responsible-investing/invest-for-good" routerLinkActive="active-link" aria-label="How we invest back into society">Invest for good ®</a></li>
    </ul>
    <ul class="miniHeaderMobile">
        <li class="active"><a routerLink="/responsible-investing/invest-for-good" routerLinkActive="active-link" aria-label="How we invest back into society">Invest for good ®</a></li>
        <li><a routerLink="/responsible-investing/shariah" routerLinkActive="active-link" aria-label="Further details on our shariah products">Shari'ah</a></li>
        <li><a routerLink="/responsible-investing/governance" routerLinkActive="active-link" aria-label="Further details on our governance procedures">Governance</a></li>
        <li><a routerLink="/responsible-investing/proxy-voting" routerLinkActive="active-link" aria-label="Further details on our proxy voting results">Proxy Voting Results</a></li>
    </ul>
</div>


<app-invest-for-good></app-invest-for-good>
<div class="contain">
    <div class="contentContainer">
        <app-heading-holder headerTitle="{{titleP}}" desc="{{descriptionP}}"></app-heading-holder>
    </div>
</div>

<div class="contain">
    <div class="contentContainer">
        <!-- Products -->
        <div class="equalHWrap eqWrap">
            <div class=" equalHW eq" *ngFor="let Prod of shariahProducts" [ngClass]="{'shake': shakeIt, 'dialog-class': true}">
                <div class="indivContainer">
                    <h6>{{Prod.name}}</h6>
                    <p>{{Prod.summary}}</p>
                    <p class="risk"><b>Risk profile: </b>{{Prod.risk_profile}}</p>
                    <p *ngIf="Prod.minimum_amount"><b>Minimum investment amount </b></p>
                    <p [innerHTML]="Prod.minimum_amount"></p>                    
                    <a class="investBtn" [routerLink]="['/invest-individual']">MORE INFO</a>
                    <br />                    
                </div>
            </div>
        </div>
        <br />
        <!-- Shariah Downloads -->
        <app-heading-holder headerTitle="Downloads"></app-heading-holder>
        <div class="downloadSection">
            <div *ngFor="let downloadItem of shariahDownloads">
                <div class="downloadHolder" *ngIf="downloadItem.storage_url">
                    <p class="textDownload">{{ downloadItem.name }}</p>
                    <a class="downloadLink" target="_blank" href="{{ downloadItem.storage_url }}" download aria-label="Click here to download pdf file">
                        <i class="fa fa-download"></i>
                        <p>Download PDF</p>
                    </a>
                </div>
            </div>
        </div>        
    </div>
</div>

<br />

<!-- MasterClass Videos -->
<div class="contain">
    <div class="contentContainer">
        <app-heading-holder headerTitle="Recent Videos"></app-heading-holder>
        <div class="masterClassContainer">
            <div *ngFor="let video of masterclassVideos; let i = index" class="masterClassVideoBlock">
                <div class="masterClassVideoItem" [ngClass]="{'lastInRow': (i%2) != 0}">
                    <iframe width="100%" height="325" frameBorder="0" [src]="video.youtubeUrl | safe" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>   
        </div>
    </div>
</div>

<!-- Shariah Think -->
<div class="contain">
    <div class="contentContainer">
        <app-heading-holder headerTitle="Recent Articles"></app-heading-holder>
        <!-- <div *ngIf="shariahThinkList.length===0" class="NoDataFound">There are no articles to display at the moment.</div>
        <div class="newsBlocksOuter">
            <div class="newsBlockRepeater" *ngFor="let item of shariahThinkList; let i = index">
                <div class="newsBlock" *ngFor="let card of item; let cardIndex = index">
                    <a class="newsBlockInner" [routerLink]="['/insights-details',card.id ]" [ngStyle]="{ 'background-image': 'url(' + card.image_thumbnail + ')'}" aria-label="More information on this article can be found here">
                        <p class="date">{{card.date}}</p>
                        <p class="heading">{{card.headline}}</p>
                    </a>
                </div>
            </div>
        </div> -->
        <br/>
        <div class="newsBlockRepeater" *ngFor="let item of shariahThinkList; let i = index">
            <div class="newsBlock">
                <a class="newsBlockInner" [routerLink]="['/insights-details',item.id]" [ngStyle]="{ 'background-image': 'url(' + item.thumbnail + ')'}" aria-label="More information on this article can be found here">
                    <p class="heading">{{item.headline}}</p>
                </a>
            </div>            
        </div>
    </div>
</div>

<!-- FAQ -->
<div class="contain">
    <div class="contentContainer">
        <app-heading-holder headerTitle="Frequently Asked Questions"></app-heading-holder>
        <div class="faqBottomLine"></div>
        <div class="faqItems">
            <div class="faq" *ngFor="let faqItem of faqList; let i = index">
                <div class="question">
                    <div class="tilte" [innerHTML]="faqItem.question"></div>
                    <div class="icon">
                        <button (click)="faqToggle(i)">{{faqRuleContent[i] ? ' - ' : ' + '}}</button>
                    </div>
                </div>
                <div *ngIf="faqRuleContent[i]" class="answer" [innerHTML]="faqItem.answer"></div>
            </div>
        </div>
        
    </div>
</div>


<app-heading-holder headerTitle="Institutional Investor" desc="{{intro}}"></app-heading-holder>

<div class="productListContainer">
    <div class="filterHolder">
        <div class="dropdownHolder">
            <div class="dropdownN" [ngClass]="status ? 'open': 'close'">
                <div class="nameHolder" (click)="clickEvent()">
                    <p class="selectedName">{{nameSelected}}</p>
                    <i class="arrow down"></i>
                </div>
                <div class="dropdownInner">
                    <p (click)="filterItem('All')">All</p>
                    <p (click)="filterItem('Equity')">Equity</p>
                    <!-- <p (click)="filterItem('Fixed Income')">Fixed Income</p> -->
                    <p (click)="filterItem('Hedge Fund')">Hedge Fund</p>
                    <p (click)="filterItem('Institutional')">Institutional</p>
                    <p (click)="filterItem('Multi-Asset')">Multi Asset</p>
                    <p (click)="filterItem('Retail')">Retail</p>
                    <p (click)="filterItem('Shari')">Shari'ah</p>
                </div>
            </div>
        </div>
    </div>
    <div class="equalHWrap eqWrap">
        <div class=" equalHW eq" *ngFor="let Prod of ProductDetails" [ngClass]="{'shake': shakeIt, 'dialog-class': true}">
            <div class="indivContainer">
                <h6>{{Prod.name}}</h6>
                <p><b>Objective: </b>{{Prod.objective}}</p>
                <p><b>Style: </b>{{Prod.style}}</p>
                <p><b>Strategy: </b>{{Prod.strategy}}</p>
                <p><b>Risk profile: </b>{{Prod.risk_profile}}</p>
                <p><b>Benchmark: </b>{{Prod.benchmark}}</p>
                <p><b>Inception date: </b>{{Prod.inception_date}}</p>
                <a class="investBtn" [href]="'mailto:info@sentio-capital.com?subject=' + Prod.name">CONTACT US</a>
                <div class="downloadHolder" *ngIf="Prod.factsheet_doc_path_url">
                    <i class="fa fa-download"></i>
                    <a target="_blank" class="downloadBtn" href="{{ Prod.factsheet_doc_path_url }}">Download fact sheet</a>
                </div>
            </div>
        </div>
    </div>
</div>
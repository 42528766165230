<div class="bannerHolder" [ngStyle]="{ 'background-image': 'url(' + thumbnail + ')'}">
    <div class="bannerHolderMiddle">
        <p class="subMainTitle">{{ date}} by {{ author }} </p>
        <h1>{{ headline }}</h1>     
    </div>
    <div class="backgroundCover"></div>
</div>
<div class="contentArticle">
    <div class="contentArticleHolder"> 
        <p class="article" [innerHTML]="_returnHtmlFromRichText(description, assetsPayload)"></p>
        <div class="videoWrapper" *ngIf="videoUrl">
            <iframe [src]="videoUrl | safe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>       
        <a class="backNav" routerLink="/insights">
            <img src="../../../assets/img/bluearrow.png" alt="" />
            <p>Back to Articles</p>
        </a>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})
export class GovernanceComponent implements OnInit {
  docsToDownload = [
    {name: "Complaint Resolution Procedure", link: "../../assets/img/Mo_02.png"},
    {name: "Treating Customers Fairly", link: "../../assets/img/Mo_02.png"},
    {name: "CRISA Compliance Policy", link: "../../assets/img/Mo_02.png"},
    {name: "Proxy Voting", link: "../../assets/img/Mo_02.png"},
    {name: "Compliance Officer Details", link: "../../assets/img/Mo_02.png"},
    {name: "Certificate of Shari’ah Compliance", link: "../../assets/img/Mo_02.png"}
  ];
 
  introP: Object;
  factsheetHold : Object;

  constructor(private data: DataService, private factsheets: DataService) { }

  ngOnInit() {
    this.data.getGovernanceParagraph().subscribe(data => {
        this.introP = data['data'][0].description;
      }
    ); 

    this.factsheets.getGovernanceFactsheets().subscribe(factsheets => {
      this.factsheetHold =factsheets['data'].map((val)=>{     
        return {
          'type': val.type,
          'name': val.name,
          'storage_url' : val.storage_url,
        }             
      })
    }
  ); 
  }

}

import { Component, OnInit } from '@angular/core'
import { DataService } from '../../data.service';
@Component({
  selector: 'app-shariah',
  templateUrl: './shariah.component.html',
  styleUrls: ['./shariah.component.css']
})
export class ShariahComponent implements OnInit {
  titleP = '';
  descriptionP = '';
  titleMc = '';
  descriptionMc = '';
  shakeIt = false;
  shariahProducts : object = [];
  shariahDownloads : object = [];
  masterclassVideos : object = [];
  shariahThinkList : any = [];
  faqList: Object = [];
  faqRuleContent: boolean[] = [];
  faqButtonExpand:any = ' + ';
  faqButtonCollapse:any = ' - ';

  
  constructor(private data: DataService) { }
  
  ngOnInit() {
    this.data.getShariahParagraphByBlock('products').subscribe(data => {
      if(data){ 
            this.titleP = data["data"][0].title;
            this.descriptionP = data["data"][0].description;
      }
    }); 
    
    this.data.getShariahParagraphByBlock('masterclass').subscribe(data => {
        if(data){ 
            this.titleMc = data["data"][0].title;
            this.descriptionMc = data["data"][0].description;
        }
    }); 
    
    this.data.getShariahProducts().subscribe(data => {
        this.shariahProducts = data["data"];
    });
    
    this.data.getShariahDownloads().subscribe(data => {
      this.shariahDownloads = data['data'].map((val)=>{     
        return {
          "name": val.name,
          "storage_url": val.storage_url
        }             
      })

      this.data.getInsights().subscribe(list => {
        this.shariahThinkList = list['data'].map((val)=>{  
          return {
            "id": val.id,
            "author": val.author,
            "date": val.date,
            "description": val.description,
            "headline": val.headline,
            "thumbnail": val.thumbnail,
            "type": val.type,
            "videoUrl": val.videoUrl,
            "displayInShariahSection":val.displayInShariahSection
          }             
        }).filter(val => val.type=='articles' && val.displayInShariahSection==true);
      })     
      

  });  
    
    this.data.getAllMasterClassInvestments().subscribe(data => {
        this.masterclassVideos = data["data"];

    });
     
    this.data.getFaqList().subscribe(data => {
        this.faqList = data["data"];
    });
    
    this.faqToggle(0);
  }
  
    wwoProductLink(arraylist){
        if(arraylist.length === 1){
            if(arraylist.indexOf("Institutional") > -1){
                return "/invest-institutional";
            }
            if(arraylist.indexOf("Financial Adviser") > -1){
                return "/invest-financial";
            }
            if(arraylist.indexOf("Individual Investor") > -1){
                return "/invest-individual";
            }          
        }else{
            if(arraylist.indexOf("Individual Investor") > -1){
                return "/invest-individual";
            }else if(arraylist.indexOf("Financial Adviser") > -1){
                return "/invest-financial";
            }else if(arraylist.indexOf("Institutional") > -1){
                return "/invest-institutional";
            }        
        }
    }  
    
    chunk(arr, chunkSize) {
      let R = [];
      for (let i = 0, len = arr.length; i < len; i += chunkSize) {
        R.push(arr.slice(i, i + chunkSize));
      }
      return R;
   } 
  
    faqToggle(index){
       this.faqRuleContent[index] = !this.faqRuleContent[index];
    }
}

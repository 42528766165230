import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { HostListener } from '@angular/core';

/*
 * GTA declarations 
 */
import { filter } from 'rxjs/operators';
declare const gtag: Function;

/*** End GTA declarations ***/

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
@HostListener('window:scroll', ['$event'])

export class AppComponent implements OnInit {
  title: string = 'main-site-portal';
  anio: number = new Date().getFullYear();
  status: boolean = false;

  constructor(private router: Router) {

    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       })
    });
    /** END : Code to Track Page View  using gtag.js */ 
}
    
ngOnDestroy() {
  window.removeEventListener('scroll', this.scroll, true);
}
ngOnInit() {
  window.addEventListener('scroll', this.scroll, true); //third parameter
}
  
scroll = (event): void => {
  
  let element = document.querySelector('.headerClass');
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
};

onActivate(event) {
  var url =this.router.url;
  const condition = ['/invest-individual', '/invest-financial', '/invest-institutional', '/about-our-philosophy', '/about-our-board','/about-our-team', '/about-our-journey','/about-invest-for-good', '/about-governance'];
  if (!condition.includes(url)){
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 16);
  }


  
}
getHeaderStyle() {
    if (this.router.url.length > 1){
      return 'whiteHeader';
    } else{
      return '';
    }

    }

    clickMenu(){
      this.status = !this.status;       
  }
  onWindowScroll(e) {  
    
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { OfferComponent } from './offer/offer.component';
import { ResponsibleInvestingComponent } from './responsible-investing/responsible-investing.component';
import { ContactComponent } from './contact/contact.component';
import { InvestComponent } from './invest/invest.component';
import { MainBannerComponent } from './component/main-banner/main-banner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { IntroTabParagraphComponent } from './intro-tab-paragraph/intro-tab-paragraph.component';
import { OurPhilosophyComponent } from './component/our-philosophy/our-philosophy.component';
import { OurBoardComponent } from './component/our-board/our-board.component';
import { OurTeamComponent } from './component/our-team/our-team.component';
import { OurJourneyComponent } from './component/our-journey/our-journey.component';
import { InvestForGoodComponent } from './component/invest-for-good/invest-for-good.component';
import { GovernanceComponent } from './component/governance/governance.component';
import { ProxyVotingComponent } from './component/proxy-voting/proxy-voting.component';
import { ShariahComponent } from './component/shariah/shariah.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { InsightsComponent } from './insights/insights.component';
import { InsightsSubComponent } from './component/insights-sub/insights-sub.component';
import { PressComponent } from './component/press/press.component';
import { InsightsDetailsComponent } from './component/insights-details/insights-details.component';
import { ProductsListComponent } from './component/products-list/products-list.component';
import { FactsheetsComponent } from './component/factsheets/factsheets.component';
import { FormsComponent } from './component/forms/forms.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { IndividualInvestorComponent } from './component/individual-investor/individual-investor.component';
import { FinancialAdvisorComponent } from './component/financial-advisor/financial-advisor.component';
import { InstitutionalComponent } from './component/institutional/institutional.component';
import { HeadingHolderComponent } from './component/heading-holder/heading-holder.component';
import { MatRadioModule } from '@angular/material/radio';
import { FooterComponent } from './component/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { TeamDetailsComponent } from './component/team-details/team-details.component';
import { AboutOurphilosophyComponent } from './about-ourphilosophy/about-ourphilosophy.component';
import { AboutContainerComponent } from './about-container/about-container.component';
import { InvestIndividualComponent } from './component/invest-individual/invest-individual.component';
import { InvestInstitutionalComponent } from './component/invest-institutional/invest-institutional.component';
import { InvestFinancialComponent } from './component/invest-financial/invest-financial.component';
import { OurPhilHolderComponent } from './component/our-phil-holder/our-phil-holder.component';
import { OurBoardHolderComponent } from './component/our-board-holder/our-board-holder.component';
import { OurTeamHolderComponent } from './component/our-team-holder/our-team-holder.component';
import { OurJourneyHolderComponent } from './component/our-journey-holder/our-journey-holder.component';
import { InvestHolderComponent } from './component/invest-holder/invest-holder.component';
import { GovernanceHolderComponent } from './component/governance-holder/governance-holder.component';
import { ProxyVotingHolderComponent } from './component/proxy-voting-holder/proxy-voting-holder.component';
import { ShariahHolderComponent } from './component/shariah-holder/shariah-holder.component';
import { MatVideoModule } from 'mat-video';
import { SafePipe } from './safe.pipe';
import { TruncatePipe } from './truncate.pipe';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    OfferComponent,
    ResponsibleInvestingComponent,
    ContactComponent,
    InvestComponent,
    ShariahComponent,
    MainBannerComponent,
    IntroTabParagraphComponent,
    OurPhilosophyComponent,
    OurBoardComponent,
    OurTeamComponent,
    OurJourneyComponent,
    InvestForGoodComponent,
    ProxyVotingComponent,
    GovernanceComponent,
    InsightsSubComponent,
    InsightsComponent,
    PressComponent,
    InsightsDetailsComponent,
    ProductsListComponent,
    FactsheetsComponent,
    FormsComponent,
    IndividualInvestorComponent,
    FinancialAdvisorComponent,
    InstitutionalComponent,
    HeadingHolderComponent,
    FooterComponent,
    TeamDetailsComponent,
    AboutOurphilosophyComponent,
    AboutContainerComponent,
    InvestIndividualComponent,
    InvestInstitutionalComponent,
    InvestFinancialComponent,
    OurPhilHolderComponent,
    OurBoardHolderComponent,
    OurTeamHolderComponent,
    OurJourneyHolderComponent,
    InvestHolderComponent,
    ShariahHolderComponent,
    GovernanceHolderComponent,
    ProxyVotingHolderComponent,
    SafePipe,
    TruncatePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    SlickCarouselModule,
    Ng2SearchPipeModule,
    FormsModule,
    MatSelectModule,
    MatRadioModule,
    HttpClientModule,
    MatVideoModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
@Component({
  selector: 'app-invest-for-good',
  templateUrl: './invest-for-good.component.html',
  styleUrls: ['./invest-for-good.component.css']
})
export class InvestForGoodComponent implements OnInit {
  introP: Object;
  CSIPara : Object;
  AcadameyPara : Object;
  TransformationPara : Object;
  InvestmentPara : Object;
  constructor(private data: DataService) { }

  ngOnInit() {
    this.data.getInvestGoodParagraph().subscribe(data => {
      var count = data['data'].length;
      var title = "";
      for (let i = 0; i < count; i++){
        title = data['data'][i].title;
        switch (title) {
          case "INVEST FOR GOOD":
              this.introP = data['data'][i].description;
              break;
              case " Sentio Academy":
                this.AcadameyPara = data['data'][i].description;
                break;

                case "Transformation":
              this.TransformationPara = data['data'][i].description;
              break;

              case "Investments":
              this.InvestmentPara = data['data'][i].description;
              break;

              case "CSI":
              this.CSIPara = data['data'][i].description;
              break;
        }
      }
      /*   this.introP = data['data'][0].description;
        this.CSIPara = data['data'][1].description;
        this.AcadameyPara = data['data'][2].description;
        this.TransformationPara = data['data'][3].description;
        this.InvestmentPara = data['data'][4].description; */
      }
    ); 
  }
}

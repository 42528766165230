import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-our-philosophy',
  templateUrl: './our-philosophy.component.html',
  styleUrls: ['./our-philosophy.component.css']
})
export class OurPhilosophyComponent implements OnInit {
  introP: Object;
  showVid: boolean = false;
  source;
  constructor(private data: DataService) { }

  ngOnInit() {
    this.data.getOurPhilotParagraph().subscribe(data => {
        this.introP = data['data'][0].description;
      }
    ); 
  }

  playVideo(){
    this.showVid = true;
    this.source = "https://www.youtube.com/embed/R-vb0Hypb7M?autoplay=1";
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {
  introP: Object;
  introSubP:Object;

  constructor(private data: DataService, private router: Router) { }

  ngOnInit(): void {
    this.data.getWhatOfferParagraph().subscribe(data => {
      this.introP =data['data'][0].description;
      this.introSubP =data['data'][0].title;
    }
  ); 
   
  }
  
  selectedTabIndex: number = 0;
  selectedIndexChange(index: number) {
      setTimeout(() => this.selectedTabIndex = index);
  }

  onTabChanged(event): void {
    switch (event.index) { 
      case 0:
        this.router.navigate(['/offer/products']);
        break;
      case 1:
        this.router.navigate(['/offer/factsheets']);
        break;
      case 2:
        this.router.navigate(['/offer/forms']);
        break;        
    }
  }
  
  changeRouteOffer(): number {
      let route = this.router.url;
      switch (route){
          case "/offer/products":
              return 0;
              break;
          case "/offer/factsheets":
              return 1;
              break;
          case "/offer/forms":
              return 2;
              break;
          default:
              return 0;
              break;          
      }
  }
  
  tabClickOffer(event): void {
      let el = event.srcElement;
      const attr = el.attributes.getNamedItem('class');
      if(attr !== null && attr !== undefined){
          if (attr.value.indexOf('mat-tab-label-content') >= 0) {
            el = el.parentElement;
          }
          const tabIndex = el.id.substring(el.id.length - 1);
          if (parseInt(tabIndex) === this.selectedTabIndex) {
            // active tab clicked
            this.router.navigate(['/offer/products']);
          }  
    }
  }
}

<app-heading-holder headerTitle="Products" desc="{{aboutP}}"></app-heading-holder>

<div class="productListContainer">
    <div class="filterHolder">
        <div class="textInfoMobile">
            <div class="textInfoHolder">
                <p *ngIf="desc"><span>{{title}}: </span> {{ desc }}</p>
            </div>
        </div>
        
        <div class="dropdownHolder">
            <div class="dropdownN" [ngClass]="status ? 'open': 'close'">
                <div class="nameHolder" (click)="clickEvent()">
                    <p class="selectedName">{{nameSelected}}</p>
                    <i class="arrow down"></i>
                </div>
                <div class="dropdownInner">
                    <p (click)="filterItem('All')">All</p>
                    <div *ngFor='let value of ProductHeaderBU'>
                        <div *ngIf="value.active">
                            <p (click)="filterItem(value.title)">{{value.title}}</p>
                        </div>            
                    </div>
                </div>
            </div>
        </div>
        <div class="textInfoHolder textInfoHolderDes">
            <p *ngIf="desc"><span>{{title}}: </span> {{ desc }}</p>
        </div>

    </div>
    <div class="equalHWrap eqWrap">
        <div class=" equalHW eq" *ngFor="let Prod of ProductDetails" [ngClass]="{'shake': shakeIt, 'dialog-class': true}">
            <div class="indivContainer">
                <h6>{{Prod.name}}</h6>
                <p>{{Prod.summary}}</p>
                <p class="risk"><b>Risk profile: </b>{{Prod.risk_profile}}</p>
                <p *ngIf="Prod.minimum_amount"><b>Minimum investment amount </b></p>
                <p [innerHTML]="Prod.minimum_amount"></p>
                
                <a class="investBtn" [routerLink]="wwoProductLink(Prod.investor_type)">MORE INFO</a>               
            
                <div class="downloadHolder" *ngIf="Prod.factsheet_doc_path_url">
                    <i class="fa fa-download"></i>
                    <a target="_blank" class="downloadBtn" href="{{ Prod.factsheet_doc_path_url }}">Download fact sheet</a>
                </div>
            </div>
        </div>
    </div>
</div>
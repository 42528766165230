import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../data.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types'

@Component({
  selector: 'app-insights-details',
  templateUrl: './insights-details.component.html',
  styleUrls: ['./insights-details.component.css']
})
export class InsightsDetailsComponent implements OnInit, OnDestroy {
  item: any;
  id: string;
  title: string;
  date: string;
  author: string;
  headline: string;
  description: string;
  displayInShariahSection: string;
  videoUrl: string;
  thumbnail: string;
  download: string;
  assetsPayload: [];

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, private data: DataService, private allArti: DataService) { }
  
  _isIncluded(asset, id) {
    if(asset.indexOf(id)) { 
      return `<img src="${asset}"/>`
    } else {
      return ``
    }
  }
  _returnHtmlFromRichText(richText, assetsPayload) {
    return documentToHtmlString(richText, 
      {        
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            let img =``;
            this.assetsPayload.forEach((asset)=>{
              img = this._isIncluded(asset, node.data.target.sys.id);                  
            })            
            return img
          },
        }
      }
    );
  }

  ngOnInit(): void {
    
    window.scrollTo(0, 0)
    
    this.route.paramMap.subscribe(params => {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);

    this.route.paramMap.subscribe(params => {
      this.item = this.route.snapshot.paramMap.get('id');
      this.data.getInsightsDetails(this.item).subscribe(val => {
        this.date = val['data'][0].date;
        this.author = val['data'][0].author;
        this.headline = val['data'][0].headline;
        this.description =  val['data'][0].description;
        this.displayInShariahSection = val['data'][0].displayInShariahSection;
        this.videoUrl = val['data'][0].videoUrl;
        this.thumbnail = val['data'][0].thumbnail;
        this.download = val['data'][0].download;
        this.assetsPayload = val['data'][0].assests;
        
      });    

    });
    });    
  }

  objectLength(obj) {
    var result = 0;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        result++;
      }
    }
    return result;
  }

  ngOnDestroy() {}
  
  forward() {}

  reverse() {}
}

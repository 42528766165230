import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-proxy-voting-holder',
  templateUrl: './proxy-voting-holder.component.html',
  styleUrls: ['./proxy-voting-holder.component.css']
})
export class ProxyVotingHolderComponent implements OnInit {
 
  introP: Object;
  introSubP:Object;
  
  constructor(private data: DataService) { }

  ngOnInit(): void {
    this.data.getResponsibleInvestingParagraph().subscribe(data => {
      this.introP =data['data'][0].description;
      this.introSubP =data['data'][0].title;
    }
  ); 

}

}

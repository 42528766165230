<div class="thinqHolder">
    <div class="headerHolder">
        <p>{{type}} </p>
    </div>
    <div class="newsBlocksOuter">
        <div class="newsBlockRepeater" *ngFor="let item of insights; let i = index">
            <div class="newsBlock" *ngIf="type==='articles'">
                <a class="newsBlockInner" [routerLink]="['/insights-details',item.id]" [ngStyle]="{ 'background-image': 'url(' + item.thumbnail + ')'}" aria-label="More information on this article can be found here">
                    <p class="heading">{{item.headline}}</p>
                </a>
            </div>
            <div class="newsBlock" *ngIf="type==='downloads'">
                <a [href]="item.download" class="newsBlockInner" [ngStyle]="{ 'background-image': 'url(' + item.thumbnail + ')'}" aria-label="More information on this article can be found here" target="_blank">
                    <p class="heading">{{item.headline}}</p>
                </a>
            </div>
            <div class="newsBlock videoWrapper" *ngIf="type==='videos'">
                <iframe class="newsBlockInner" [src]="item.videoUrl | safe" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
             </div>
        </div>
    </div>
</div>
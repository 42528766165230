<div class="contain">
    <div class="containInner">
        <app-intro-tab-paragraph headerTitle="Invest for Good ®" text="{{introP}}">
        </app-intro-tab-paragraph>
    </div>
</div>

<div class="investHold">
    <img src="../../../assets/img/s3Mobile.png" class="bannerImgMobile" alt="" />
    <img src="../../../assets/img/Invetsments.png" class="bannerImg" alt="" />
    <div class="investInfoBlock">
        <div class="investInfoBlockInner redBG">
            <h2>Investments</h2>
            <p [innerHTML]="InvestmentPara"></p>
            <p class="addOnPara">To view our ESG policy please <a class="insidePlink" routerLink="/responsible-investing/governance">click here</a>.</p>
        </div>
    </div>
</div>

<div class="investHold">
    <img src="../../../assets/img/s2Mobile.png" class="bannerImgMobile" alt="" />
    <img src="../../../assets/img/Transformation.png" class="bannerImg" alt="" />
    <div class="investInfoBlock">
        <div class="investInfoBlockInner darkBlueBG">
            <h2>Transformation</h2>
            <p [innerHTML]="TransformationPara"></p>
        </div>
    </div>
</div>

<div class="investHold">
    <img src="../../../assets/img/s4Mobile.png" class="bannerImgMobile" alt="" />
    <img src="../../../assets/img/CSI.png" class="bannerImg" alt="" />
    <div class="investInfoBlock">
        <div class="investInfoBlockInner lightBlueBG">
            <h2>CSI</h2>
            <div class="floatRightBlock">
                <p [innerHTML]="CSIPara"></p>
            </div>
        </div>
    </div>
</div>

<div class="investHold">
    <div class="bannerImgHolder">
        <img src="../../../assets/img/s1Mobile.png" class="bannerImgMobile" alt="" />
        <img src="../../../assets/img/Sentio Academy.png" class="bannerImg" alt="" />
    </div>
    <div class="investInfoBlock">
        <div class="investInfoBlockInner lightBlueBG">
            <h2>Sentio Academy</h2>
            <p [innerHTML]="AcadameyPara"></p>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invest-institutional',
  templateUrl: './invest-institutional.component.html',
  styleUrls: ['./invest-institutional.component.css']
})
export class InvestInstitutionalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<header [ngClass]="getHeaderStyle()" class="headerClass">
    <div class="container mainMenu">
        <!-- <a routerLink="/" class="logo"><img src="../assets/img/logo.png"/></a> -->
        <a routerLink="/" class="logo" alt="Sentio Logo" aria-label="Click here to navigate back to the home page"></a>
        <button class="hamburger hamburger--spin" type="button" (click)="clickMenu()" [ngClass]="status ? 'is-active' : ''">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        <nav [ngClass]="status ? 'is-open' : ''">
            <ul>
                <li><a href="#" routerLink="/about-our-philosophy" (click)="clickMenu()" routerLinkActive="active-link" aria-label="More details about Sentio">About us</a></li>
                <li><a href="#" routerLink="/offer" (click)="clickMenu()" routerLinkActive="active-link" aria-label="Details our products can be found here">What we offer</a></li>
                <li><a href="#" routerLink="/responsible-investing" (click)="clickMenu()" routerLinkActive="active-link" aria-label="Details Responsible Investing can be found here">Responsible Investing</a></li>  
                <li><a href="#" routerLink="/insights" (click)="clickMenu()" routerLinkActive="active-link" aria-label="Articles videos and more">Insights</a></li>
                <li><a href="#" routerLink="/contact" (click)="clickMenu()" routerLinkActive="active-link" aria-label="All of our contact details can be found here">Contact</a></li>
                <li><a class="investNowLink" href="#" (click)="clickMenu()" routerLink="/invest-individual" routerLinkActive="active-link" aria-label="Explore our products">Invest now</a></li>
            </ul>

        </nav>

    </div>
</header>

<div class="container mainContent">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
<app-footer></app-footer>
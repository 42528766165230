<div class="pressHolder">
    <div class="headerHolder">
        <p>Press</p>
    </div>
    <div class="newsBlocksOuter">
        <div class="newsBlock" *ngFor="let card of hold">
            <p class="date">{{card.date}}</p>
            <img [src]="card.logo" alt="" />
            <a class="link" href="{{card.external_link}}" target="_blank" aria-label="More information on this article can be found here">{{card.headline}}</a>
            <p class="heading">{{card.description}}</p>
        </div>
    </div>
</div>
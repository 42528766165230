import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
  
})
export class AboutComponent implements OnInit {
  aboutP: Object;
  aboutSupP: Object;
  constructor(private data: DataService) {
   }

   ngOnInit() {
    this.data.getAboutParagraph().subscribe(data => {
        this.aboutP = data['data'][0].description;
        this.aboutSupP = data['data'][0].title;
      }
    ); 
  }

}

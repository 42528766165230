import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  instagramFeeds: Object = [];
  instagramPopEl = 'block';
  
  slideConfig = {
    "slidesToShow": 5, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":true,
    "infinite": false,
    "responsive": [
          {
            "breakpoint": 1000,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
  };
 
  slickInit(e) {
  }
  
  breakpoint(e) {
  }
  
  afterChange(e) {
  }
  
  beforeChange(e) {
  }    
  
  constructor(private data: DataService) { }

  ngOnInit() {}
  
  hover="#9E002F";
  
}

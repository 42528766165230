import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  anio: number = new Date().getFullYear();
  footer: Object;

  constructor(private data: DataService) { }

  ngOnInit() {
    this.data.getFooter().subscribe(data => {
        this.footer = data['data'][0].description;
      }
    ); 
  }

}

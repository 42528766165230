<app-main-banner headerTitle="What we offer" showBGDark="showBGDark" subMainTitle="{{introSubP}}" subTitle="{{introP}}" [styles]="{backgroundColor: '#9E002F'}"></app-main-banner>

<div class="content">
    <div class="contentContainer offerTabs">        
        <mat-tab-group (selectedTabChange)="onTabChanged($event)" [selectedIndex]="changeRouteOffer()" (selectedIndexChange)="selectedIndexChange($event)" (click)="tabClickOffer($event)">
            <mat-tab label="Products" aria-label="You may view our products here">
                <app-products-list></app-products-list>
            </mat-tab>
            <mat-tab label="Factsheets" aria-label="See all our product factsheets here">
                <ng-template matTabContent>
                    <app-factsheets></app-factsheets>
                </ng-template>
            </mat-tab>
            <mat-tab label="Application Forms" aria-label="See all our application forms here">
                <ng-template matTabContent>
                    <app-forms></app-forms>
                </ng-template>
            </mat-tab>            
        </mat-tab-group>
        
    </div>


</div>
<app-intro-tab-paragraph headerTitle="Our Team" text="{{ introP}}"></app-intro-tab-paragraph>

<div class="headingHolder">
    <h2>Investment Team:</h2>
    <p>Portfolio Managers, Analysts and Traders</p>
</div>

<ngx-slick-carousel class="carouselB" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of invTeam" class="slide">
        <a [routerLink]="['/team-details', slide.full_name]" attr.aria-label="More information on {{slide.full_name}}">
            <img src="{{ slide.image_thumbnail }}" attr.alt="{{slide.full_name}}" width="100%">
            <div class="textHolder">
                <p class="personName">{{ slide.full_name }}</p>
                <p class="personTitle">{{ slide.position }}</p>
            </div>
        </a>
    </div>
</ngx-slick-carousel>


<div class="headingHolder bottomDiv">
    <h2>Business Team: </h2>
    <p>Operations and Business Development</p>
</div>


<ngx-slick-carousel class="carouselB" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slideB of BusTeam" class="slide">
        <a [routerLink]="['/team-details', slideB.full_name]" attr.aria-label="More information on {{slideB.full_name}}">
            <img src="{{ slideB.image_thumbnail }}" attr.alt="{{slideB.full_name}}" alt="" width="100%">
            <div class="textHolder">
                <p class="personName">{{ slideB.full_name }}</p>
                <p class="personTitle">{{ slideB.position }}</p>
            </div>
        </a>
    </div>
</ngx-slick-carousel>
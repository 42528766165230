<div class="homeBanner">
    <div class="homeContentHolder">
        <h5>Welcome to Sentio</h5>
        <h1>Fundamentally based, quantitatively robust investing</h1>
    </div>
    <div class="homeBannerExtra"></div>
</div>

<div class="homeLinkHolder" [ngStyle]="{backgroundColor: hover }" (mouseleave)="hover='#9E002F'">
    <div class="homeLinkHolderInner">
        <div class="homeLinkHolderIndividual firstCol" (mouseover)="hover='#9E002F'">
            <div class="inmostLink">
                <p>I am an</p>
                <h3>individual investor</h3>
                <a href="" routerLink="/invest-individual" aria-label="If you're an investor, go here to see our product offerring">Invest Now</a>
            </div>
        </div>
        <div class="homeLinkHolderIndividual middleCol" (mouseover)="hover='#012D6D'">
            <div class="inmostLink">
                <p>I am an</p>
                <h3>independent financial advisor</h3>
                <a href="" routerLink="/invest-financial" aria-label="If you're an independent financial advisor, go here to see our product offerring">Invest Now</a>
            </div>
        </div>
        <div class="homeLinkHolderIndividual lastCol" (mouseover)="hover='#001640'">
            <div class="inmostLink">
                <p>I am an</p>
                <h3>institutional investor</h3>
                <a href="" routerLink="/invest-institutional" aria-label="If you're an institutional investor, go here to see our product offerring">Invest Now</a>
            </div>
        </div>
    </div>
</div>
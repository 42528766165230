<app-intro-tab-paragraph headerTitle="Governance" text="{{introP}}"></app-intro-tab-paragraph>

<div class="downloadSection">
    <div *ngFor="let item of factsheetHold">
        <div class="downloadHolder" *ngIf="item.storage_url">
            <p class="textDownload">{{ item.name }}</p>
            <a class="downloadLink" target="_blank" href="{{ item.storage_url }}" download aria-label="Click here to download pdf file">
                <!-- <img src="../../../assets/img/downloadArrow.png" alt="" /> -->
                <i class="fa fa-download"></i>
                <p>Download PDF</p>
            </a>
        </div>

    </div>
</div>
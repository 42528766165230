import { Component, OnInit, Input } from '@angular/core';
import {   animate,   keyframes,   query,   stagger,   style,   transition,   trigger } from '@angular/animations';



@Component({
  animations: [

    trigger('listAnimation', [
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('300ms', [
          animate('1s ease-in', keyframes([
            style({ width: 0, opacity: 0}),
            style({ width: "50%", opacity: 0.5}),
            style({ width: "100%", opacity: 1}),
          ]))]), { optional: true }),

        
      ])
    ]),
    trigger('listAnimationHeader', [
      transition('* => *', [
        query(':enter', stagger('800ms', [
          animate('1s ease-in', keyframes([
            style({  opacity: 0}),
            style({  opacity: 0.5}),
            style({  opacity: 1}),
          ]))]), { optional: true }),

        
      ])
    ])
  ],
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.css']
  })

export class MainBannerComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() showBGDark: string;
  @Input() subTitle: string;
  @Input() subMainTitle: string;
  @Input() styles: any = {};

  items = [];
  itemsH = [];
  constructor() {
    this.initItems();
   }

  ngOnInit(): void {

  }

  initItems(){
    this.items = [
      'Hey this is an item'
    ];    
    this.itemsH = [
      'Hey this is an item'
    ];   
  }
 
}

<app-main-banner headerTitle="Insights" showBGDark="showBGDark" subMainTitle="{{introSupP}}" subTitle="{{introP}}" [styles]="{backgroundColor: '#001640'}"></app-main-banner>
<div class="hrOut">
    <div class="hrIn"></div>
</div>
<div class="newsHolderOuter">
    <div class="newsHolder">
        <mat-tab-group [selectedIndex]="this.section">
            <mat-tab label="Articles">
                <app-insights-sub [type]="'articles'"></app-insights-sub>
            </mat-tab>
            <mat-tab label="Downloads">
                <app-insights-sub [type]="'downloads'"></app-insights-sub>
            </mat-tab>
            <mat-tab label="Videos">
                <app-insights-sub [type]="'videos'"></app-insights-sub>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-our-journey',
  templateUrl: './our-journey.component.html',
  styleUrls: ['./our-journey.component.css']
})
export class OurJourneyComponent implements OnInit {
  introP: Object;
  ourJourney: Object;
  constructor(private data: DataService) { }

  ngOnInit() {
    this.data.getOurJourneyParagraph().subscribe(data => {
        this.introP = data['data'][0].description;
      }
    ); 
    this.data.getOurJourney().subscribe(data => {
      this.ourJourney = data['data'];
      }
    );    
  }
}

<app-heading-holder headerTitle="Application Forms"></app-heading-holder>

<div class="downloadSection">
    <div *ngFor="let item of formHold">
        <div class="downloadHolder" *ngIf="item.storage_url">
            <p class="textDownload">{{ item.name }}</p>
            <a class="downloadLink" href="{{ item.storage_url }}" download aria-label="Download the form here" target="_blank">
                <i class="fa fa-download"></i>
                <p>Download PDF</p>
            </a>
        </div>
    </div>
</div>
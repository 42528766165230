import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-container',
  templateUrl: './about-container.component.html',
  styleUrls: ['./about-container.component.css']
})
export class AboutContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

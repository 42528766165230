import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {
  formHold : Object;

  constructor(private forms: DataService) { }

  ngOnInit(): void {
      this.forms.getOfferForms().subscribe(forms => {
        this.formHold = forms['data'].map((val)=>{     
          return {
            'type': val.type,
            'name': val.name,
            'storage_url' : val.storage_url,
          }             
        })
      }
      ); 
      
  }

}

import { NgModule } from '@angular/core';
import {
    MatTabsModule
} from '@angular/material/tabs';


@NgModule({
    imports: [MatTabsModule],
    exports: [MatTabsModule]
})

export class AngularMaterialModule { }
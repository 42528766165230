import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-proxy-voting',
  templateUrl: './proxy-voting.component.html',
  styleUrls: ['./proxy-voting.component.css']
})
export class ProxyVotingComponent implements OnInit {
 
  introP: Object;
  factsheetHold : Object;

  constructor(private data: DataService, private factsheets: DataService) { }

  ngOnInit() {
    this.data.getProxyVotingParagraph().subscribe(data => {
        this.introP = data['data'][0].description;
      }
    ); 

    this.factsheets.getProxyVotingFactsheets().subscribe(factsheets => {
      this.factsheetHold =factsheets['data'].map((val)=>{     
        return {
          'type': val.type,
          'name': val.name,
          'storage_url' : val.storage_url,
        }             
      });
    }
  ); 
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }
  baseURL = "https://api.sentio-capital.com";  
  bucketURL = "rmi-sentio-capital-production";

  getFooter() {
    return this.http.get(this.baseURL +'/content/sentioCapitalPageBlocks/footer');
  }

  getContact() {
    return this.http.get(this.baseURL +'/contact/sentioCapitalContact/');
  }

  getAboutParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalPageBlocks/about-us');
  }

  getInsightsParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalPageBlocks/insights');
  }

  getOurPhilotParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalAboutUs/our-philosophy');
  }

  getOurBoardParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalAboutUs/our-board');
  }

  getOurTeamParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalAboutUs/our-team');
  }

  getOurJourneyParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalAboutUs/our-journey');
  }

  getInvestGoodParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalResponsibleInvesting/invest-for-good');
  }

  getGovernanceParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalResponsibleInvesting/governance');
  }

  getGovernanceFactsheets() {
    return this.http.get(this.baseURL +'/documents/sentioCapitalDocuments/governance');
  }

  getProxyVotingParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalResponsibleInvesting/proxy-voting');
  }

  getProxyVotingFactsheets() {
    return this.http.get(this.baseURL +'/documents/sentioCapitalDocuments/proxy_voting'); 
  }
  
  getWhatOfferParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalPageBlocks/what-we-offer');
  }
  
  getResponsibleInvestingParagraph() {
    return this.http.get(this.baseURL +'/content/sentioCapitalPageBlocks/responsible-investing');
  }  
  // -----
  getBoardMembers() {
    return this.http.get(this.baseURL +'/members/sentioCapitalMembers/Board');
  }

  getInvestmentMembers() {
    return this.http.get(this.baseURL +'/members/sentioCapitalMembers/Investment');
  }

  getBusinessMembers() {
    return this.http.get(this.baseURL +'/members/sentioCapitalMembers/Business');
  }

  getMember(fullname:string) {
    var url = this.baseURL +'/membersdetails/sentioCapitalMembers/' + fullname;
    return this.http.get(url);
  }
  // -----
  getOfferFactsheets() {
    return this.http.get(this.baseURL +'/documents/sentioCapitalDocuments/factsheet');
  }
  
  getOfferForms() {
    return this.http.get(this.baseURL +'/documents/sentioCapitalDocuments/application_forms');
  }  

  getInsights() {
    var url = this.baseURL +'/insights/sentioCapitalThinq/';
    return this.http.get(url);
  }
  
  getInsightsDetails(id: number) {
    var url = this.baseURL +'/insights/sentioCapitalThinq/'+id;
    return this.http.get(url);
  }
  
  getFaqList(){
    return this.http.get(this.baseURL +'/shariahfaq/sentioCapitalShariahFaq/');
  }

  getOurJourney(){
    return this.http.get(this.baseURL +'/ourjourney/sentioCapitalOurJourney/');
  }
  
  getProductDesc() {
    return this.http.get(this.baseURL +'/content/sentioCapitalPageBlocks/products');
  }

  getInvestProduct() {
    return this.http.get(this.baseURL +'/content/sentioCapitalInvestmentsTypes/');
  }

  getAllProducts() {
    return this.http.get(this.baseURL +'/products/sentioCapitalProducts');
  }

  getInvestPara() {
    return this.http.get(this.baseURL +'/content/sentioCapitalPageBlocks/invest-now');
  }

  getInvestIndPara() {
    return this.http.get(this.baseURL +'/content/sentioCapitalInvestNow/individual-investor');
  }

  getInvestFinanPara() {
    return this.http.get(this.baseURL +'/content/sentioCapitalInvestNow/financial-adviser');
  }

  getInvestInstPara() {
    return this.http.get(this.baseURL +'/content/sentioCapitalInvestNow/institutional');
  }
  
  getFinancialAdviserProducts() {
    return this.http.get(this.baseURL +'/products/sentioCapitalProducts/investorType/Financial%20Adviser');
  }

  getInstitutionalProducts() {
    return this.http.get(this.baseURL +'/products/sentioCapitalProducts/investorType/Institutional');
  }
  
  getIndividualInvestorProducts() {
    return this.http.get(this.baseURL +'/products/sentioCapitalProducts/investorType/Individual%20Investor');
  }
  
  getShariahProducts() {
    return this.http.get(this.baseURL +"/products/sentioCapitalProducts/investmentType/Shari'ah"); 
  }  

  getShariahDownloads() {
    return this.http.get(this.baseURL +"/documents/sentioCapitalDocuments/product_mdd/yes"); 
  }  

  getAllMasterClassInvestments() {
    return this.http.get(this.baseURL +'/shariahmasterclass/sentioCapitalShariahInvestmentsMasterclass/');
  }
  
  getShariahParagraphByBlock(block: string){
      switch(block){
          case "products":
              return this.http.get(this.baseURL +'/content/sentioCapitalResponsibleInvesting/shariah');
              break;
          case "masterclass":
              return this.http.get(this.baseURL +'/content/sentioCapitalResponsibleInvesting/shariah-masterclass');
              break;
          default:
              return null;
              break;
      }  
  }

  
}

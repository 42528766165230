import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { OfferComponent } from './offer/offer.component';
import { ResponsibleInvestingComponent } from './responsible-investing/responsible-investing.component';
import { ContactComponent } from './contact/contact.component';
import { InvestComponent } from './invest/invest.component';
import { InsightsComponent } from './insights/insights.component';
import { InsightsDetailsComponent } from './component/insights-details/insights-details.component';
import { TeamDetailsComponent } from './component/team-details/team-details.component';

import { FactsheetsComponent } from './component/factsheets/factsheets.component';
import { FormsComponent } from './component/forms/forms.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { InvestIndividualComponent } from './component/invest-individual/invest-individual.component';
import { InvestFinancialComponent } from './component/invest-financial/invest-financial.component';
import { InvestInstitutionalComponent } from './component/invest-institutional/invest-institutional.component';

import { OurPhilHolderComponent } from './component/our-phil-holder/our-phil-holder.component';
import {OurTeamHolderComponent} from './component/our-team-holder/our-team-holder.component';
import {OurJourneyHolderComponent} from './component/our-journey-holder/our-journey-holder.component';
import {OurBoardHolderComponent} from './component/our-board-holder/our-board-holder.component';
import {InvestHolderComponent} from './component/invest-holder/invest-holder.component';
import {GovernanceHolderComponent} from './component/governance-holder/governance-holder.component';
import { ProxyVotingHolderComponent } from './component/proxy-voting-holder/proxy-voting-holder.component';
import { ShariahHolderComponent } from './component/shariah-holder/shariah-holder.component';

const routes: Routes = [
  { path: 'about', component: AboutComponent},
  { path: 'about-our-philosophy', component: OurPhilHolderComponent},
  { path: 'about-our-board', component: OurBoardHolderComponent},
  { path: 'about-our-team', component: OurTeamHolderComponent},
  { path: 'about-our-journey', component: OurJourneyHolderComponent},
  { path: 'offer', component: OfferComponent},
  { path: 'offer/products', component: OfferComponent},
  { path: 'offer/factsheets', component: OfferComponent},
  { path: 'offer/forms', component: OfferComponent},
  { path: 'responsible-investing', component: ResponsibleInvestingComponent},
  { path: 'responsible-investing/invest-for-good', component: InvestHolderComponent},
  { path: 'responsible-investing/governance', component: GovernanceHolderComponent},  
  { path: 'responsible-investing/proxy-voting', component: ProxyVotingHolderComponent}, 
  { path: 'responsible-investing/shariah', component: ShariahHolderComponent}, 
  { path: 'contact', component: ContactComponent},
  { path: 'insights', component: InsightsComponent},
  { path: 'insights-details/:id', component: InsightsDetailsComponent },
  { path: 'invest', component: InvestComponent},
  { path: 'invest-individual', component: InvestIndividualComponent},
  { path: 'invest-financial', component: InvestFinancialComponent},
  { path: 'invest-institutional', component: InvestInstitutionalComponent},

  { path: 'team-details/:fullname', component: TeamDetailsComponent },
  {path: '404', component: NotFoundComponent},
  {path: '**', component: HomeComponent}
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {initialNavigation: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

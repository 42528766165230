import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-intro-tab-paragraph',
  templateUrl: './intro-tab-paragraph.component.html',
  styleUrls: ['./intro-tab-paragraph.component.css']
})
export class IntroTabParagraphComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() text: string;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.css']
})
export class OurTeamComponent implements OnInit {
  title = 'ngSlick';
  introP: Object;
  invTeam : Object;
  BusTeam : Object;
  constructor(private data: DataService, private teamInv: DataService, private teamB: DataService) { }

  ngOnInit() {
    this.data.getOurTeamParagraph().subscribe(data => {
        this.introP = data['data'][0].description;
      }
    ); 
    this.teamInv.getInvestmentMembers().subscribe(teamInv => {
      this.invTeam = teamInv['data'].map((val)=>{     
        return {
          "full_name": val.fullname,
          "position": val.position,
          "linkedin_url": val.linkedinUrl,
          "main_image":  val.main_image,
          "image_thumbnail":  val.image_thumbnail,
          "tag": val.tag,
          "key_facts": val.key_facts,
          "biography": val.biography,
          "qualifications": val.qualifications
        }             
      })
    }); 
    this.teamB.getBusinessMembers().subscribe(teamB => {
      this.BusTeam = teamB['data'].map((val)=>{     
        return {
          "full_name": val.fullname,
          "position": val.position,
          "linkedin_url": val.linkedinUrl,
          "main_image":  val.main_image,
          "image_thumbnail":  val.image_thumbnail,
          "tag": val.tag,
          "key_facts": val.key_facts,
          "biography": val.biography,
          "qualifications": val.qualifications
        }             
      })
    });
  };
 
  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":true,
    "infinite": false,
    "responsive": [
          {
            "breakpoint": 1000,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
  };
  
 
  slickInit(e) {
  }
  
  breakpoint(e) {
  }
  
  afterChange(e) {
  }
  
  beforeChange(e) {
  }
 
  
 

}

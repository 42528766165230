import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-factsheets',
  templateUrl: './factsheets.component.html',
  styleUrls: ['./factsheets.component.css']
})
export class FactsheetsComponent implements OnInit {
  factsheetHold : Object;
  docsToDownload = [
    {name: "Sentio SCI* General Equity Fund", link: "../../assets/img/Mo_02.png"},
    {name: "Sentio SCI* Balanced Fund", link: "../../assets/img/Mo_02.png"},
    {name: "Sentio SCI* Hikma Shari’ah General Equity Fund", link: "../../assets/img/Mo_02.png"},
    {name: "Sentio SCI* Hikma Shari’ah Balanced Fund", link: "../../assets/img/Mo_02.png"},
    {name: "Amplified Optimised Equity Fund", link: "../../assets/img/Mo_02.png"}
  ];
  constructor(private factsheets: DataService) { }

  ngOnInit(): void {
    this.factsheets.getOfferFactsheets().subscribe(factsheets => {
      this.factsheetHold =factsheets['data'].map((val)=>{     
        return {
          'type': val.type,
          'name': val.name,
          'storage_url' : val.storage_url,
        }             
      })
    }
  ); 
  }

}

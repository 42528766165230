import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-board-holder',
  templateUrl: './our-board-holder.component.html',
  styleUrls: ['./our-board-holder.component.css']
})
export class OurBoardHolderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import {animate, keyframes, query, stagger,style,transition, trigger} from '@angular/animations';

@Component({
  animations: [

    trigger('listAnimation', [
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('200ms', [
          animate('1s ease-in', keyframes([
            style({ opacity: .5, transform: 'translateY(15px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
          ]))]), { optional: true }),

        
      ])
    ])
  ],
  selector: 'app-individual-investor',
  templateUrl: './individual-investor.component.html',
  styleUrls: ['./individual-investor.component.css']
})
export class IndividualInvestorComponent implements OnInit {
  selectedProfile: string;
  profiles: string[] = ['Moderate', 'Moderate Aggressive', 'Aggressive'];

  public ProductDetails: object = [];

  changeProfile(name: string) {


    var count = 0;
    this.ProductDetails = [];
    for (var k = 0; k < this.objectLength(this.ProductsBU); k++) {
      if (this.ProductsBU[k].risk_profile==name) {
        this.ProductDetails[count] = this.ProductsBU[k];
        count++;
      }
    }


    return this.ProductDetails;
  }

  constructor(private data: DataService, private dataList: DataService) {

  }

  intro: Object;
  ProductsBU: Object;

  ngOnInit(): void {
    this.data.getInvestIndPara().subscribe(data => {
      this.intro = data["data"][0].description;
    });

    this.dataList.getIndividualInvestorProducts().subscribe(dataList => {
      this.ProductsBU = dataList['data'];
      this.ProductDetails = dataList['data'];
    });
  }


  objectLength(obj) {
    var result = 0;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        result++;
      }
    }
    return result;
  }
}

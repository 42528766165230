import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-heading-holder',
  templateUrl: './heading-holder.component.html',
  styleUrls: ['./heading-holder.component.css']
})
export class HeadingHolderComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() desc: string;
  shariahMasterclassBlock: boolean = false;
  static cnt: number = 0;
  
  constructor(private router: Router) { }

  ngOnInit(): void {
      let pageLastFragment = this.router.url.split('/').pop();
      if(pageLastFragment === "shariah") {
          HeadingHolderComponent.cnt++;
          if(HeadingHolderComponent.cnt == 2) {
              this.shariahMasterclassBlock = true;
          }else{
              this.shariahMasterclassBlock = false;
          }
      }
  }

}

<app-main-banner headerTitle="Contact Us" subTitle="To find out more about our products, services or how we can be of assistance" [styles]="{'background-image': 'url(' + bgImage + ')'}"></app-main-banner>
<div class="content">
    <div class="contentContainer">
        <div class="contentContainerInner">
            <app-heading-holder headerTitle="Get in touch"></app-heading-holder>
        </div>
        <div class="contentContainerInner">
            <p><b>Location</b></p>
            <p [innerHTML]="location"></p>
            <br>
            <p><b>Telephone number</b></p>
            <p>{{tel}}</p>
            <br>
            <p><b>Email</b></p>
            <p><a href="mailto:{{email}}">{{email}}</a></p>
            <br>
            <p><b>Careers</b></p>
            <p><a href="mailto:{{careers}}">{{careers}}</a></p>
        </div>

    </div>
    <div class="mapsHolder">
        <div class="mapouter">
            <div class="gmap_canvas">
                <iframe width="100%" height="375" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.1318194995874!2d28.045241315028377!3d-26.127244633472127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950cc6aadb22c9%3A0x332ba1bdeea78f5f!2sIllovo%20Edge!5e0!3m2!1sen!2sza!4v1642428766085!5m2!1sen!2sza" style="border:0;" allowfullscreen="" loading="lazy" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">                   
                </iframe>                
                <a href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon/"></a>
            </div>
            <style>
                .mapouter {
                    position: relative;
                    text-align: right;
                    height: 375px;
                    width: 100%;
                }
                
                .gmap_canvas {
                    overflow: hidden;
                    background: none!important;
                    height: 375px;
                    width: 100%;
                }
            </style>
        </div>
    </div>

</div>
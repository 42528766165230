import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contact: Object;
  bgImage: string;
  location: string;
  tel: string;
  email:string;
  careers:string;
  googleUrl:string;
  title:string;

  constructor(private data: DataService) { }

  ngOnInit(): void {
    this.data.getContact().subscribe(data => {
      this.contact = data;
      this.title = this.contact['data'][0].title;
      this.bgImage = this.contact['data'][0].image;
      this.location = this.contact['data'][0].location;
      this.tel = this.contact['data'][0].telephoneNumber;
      this.email = this.contact['data'][0].email;
      this.careers = this.contact['data'][0].careers;
      this.googleUrl = this.contact['data'][0].googleMap;      
    }
  ); 
  
  }

}

<div class="bannerHolder" [style.backgroundImage]="'url('+ image_main +')'">
    <a [style.display]="viewPrev ? 'inline-block':'none'" [routerLink]="['/about-our-team']" class="leftArrow"><img src="../../../assets/img/whiteArrow.png" alt="" /></a>
    <div class="bannerHolderMiddle">
        <p class="subMainTitle">{{ position}}</p>
        <h1>{{fullName}}</h1>
        <div class="linkHolder">
            <a [style.display]="showLinkd ? 'inline-block':'none'" href="{{linkedin_url}}" target="_blank"><img src="../../../assets/img/linkdIcn.png" alt="" /></a>
            <a [style.display]="showTwitter ? 'inline-block':'none'" style="margin-left: 15px;" href="{{twitter_url}}" target="_blank"><img src="../../../assets/img/twitter.png" alt="" /></a>
        </div>
    </div>    
</div>
<div class="contentHolder">
    <div class="contentHolderInner">
        <div class="ind">
            <p class="title">Fun facts</p>
            <p [innerHTML]="key_facts"></p>
        </div>
        <div class="ind">
            <p class="title">Biography</p>
            <p [innerHTML]="biography"></p>
        </div>
        <div class="ind">
            <p class="title">Qualification</p>
            <p [innerHTML]="qualifications"></p>
        </div>
    </div>
    <a class="backNav" routerLink="/about-our-board">
        <img src="../../../assets/img/bluearrow.png" alt="" />
        <p>Back</p>
    </a>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-phil-holder',
  templateUrl: './our-phil-holder.component.html',
  styleUrls: ['./our-phil-holder.component.css']
})
export class OurPhilHolderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

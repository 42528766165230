<div class="bannerHolder" [ngStyle]="styles">
    <div class="bannerHolderMiddle">
        <div class="hrLineHolder" [@listAnimation]="items.length">
            <div class="hrLine" *ngFor="let Prod of items"></div>
        </div>
        <div class="fullContainerAni" [@listAnimationHeader]="itemsH.length">
            <div class="fullContainerInner" *ngFor="let Prod of itemsH">
                <div class="halfContainer">
                    <p class="subMainTitle" [innerHTML]="subMainTitle"></p>
                    <h1 [innerHTML]="headerTitle"></h1>
                </div>
                <div class="halfContainer">
                    <p class="subTitle" [innerHTML]="subTitle"></p>
                </div>
            </div>
        </div>
    </div>
    <div class="backgroundCover" [class]="showBGDark"></div>
</div>
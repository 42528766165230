import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import {animate, keyframes, query, stagger,style,transition, trigger} from '@angular/animations';

@Component({
  animations: [

    trigger('listAnimation', [
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('200ms', [
          animate('1s ease-in', keyframes([
            style({ opacity: .5, transform: 'translateY(15px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
          ]))]), { optional: true }),

        
      ])
    ])
  ],
  selector: 'app-financial-advisor',
  templateUrl: './financial-advisor.component.html',
  styleUrls: ['./financial-advisor.component.css']
})
export class FinancialAdvisorComponent implements OnInit {
  selectedProfile: string;
  profiles: string[] = ['Conservative', 'Moderate', 'Moderate Aggressive', 'Aggressive'];

  public ProductDetails: object = [];
  public Products = [
    {
      title: 'Sentio SCI General Equity Fund',
      asisa: 'Equity',
      benchmark: 'FTSE / JSE Capped Shareholder Weighted Index (SWIX)',
      lisp: 'Glacier, Momentum',
      risk: 'Moderate Aggressive',
      factsheet: '../../assets/img/Mo_02.png'
    },
    {
      title: 'Sentio SCI Balanced Fund',
      asisa: 'Multi-Asset High Equity',
      benchmark: 'ASISA SA Multi-Asset High Equity category average',
      lisp: 'Glacier, Momentum',
      risk: 'Conservative',
      factsheet: '../../assets/img/Mo_02.png'
    },
    {
      title: 'Sentio SCI Hikma Shari’ah General Equity Fund',
      asisa: 'Multi-Asset High Equity',
      benchmark: 'ASISA SA Multi-Asset High Equity category average',
      lisp: 'Glacier, Momentum',
      risk: 'Aggressive',
      factsheet: '../../assets/img/Mo_02.png'
    },
    {
      title: 'Sentio SCI Hikma Shari’ah Balanced Fund',
      asisa: 'Multi-Asset High Equity',
      benchmark: 'ASISA SA Multi-Asset High Equity category average',
      lisp: 'Glacier, Momentum',
      risk: 'Aggressive',
      factsheet: '../../assets/img/Mo_02.png'
    },
    {
      title: 'Sentio SCI General Equity Fund',
      asisa: 'Multi-Asset High Equity',
      benchmark: 'ASISA SA Multi-Asset High Equity category average',
      lisp: 'Glacier, Momentum',
      risk: 'Conservative',
      factsheet: '../../assets/img/Mo_02.png'
    },
    {
      title: 'Sentio SCI Hikma Shari’ah Balanced Fund',
      asisa: 'Multi-Asset High Equity',
      benchmark: 'ASISA SA Multi-Asset High Equity category average',
      lisp: 'Glacier, Momentum',
      risk: 'Aggressive',
      factsheet: '../../assets/img/Mo_02.png'
    },
    {
      title: 'Sentio SCI Hikma Shari’ah Balanced Fund',
      asisa: 'Multi-Asset High Equity',
      benchmark: 'ASISA SA Multi-Asset High Equity category average',
      lisp: 'Glacier, Momentum',
      risk: 'Aggressive',
      factsheet: '../../assets/img/Mo_02.png'
    }
  ];
  changeProfile(name: string) {
    let obj = this.Products.filter(m => m.risk == name);
    this.ProductDetails = obj;
    return this.ProductDetails;
  }

  constructor(private data: DataService, private listProducts: DataService) {
  }

  intro: Object;
  ProductsBU: Object;

  ngOnInit(): void {
    this.listProducts.getFinancialAdviserProducts().subscribe(listProducts => {
      this.ProductsBU = listProducts['data'];
      this.ProductDetails = listProducts['data'];
    });

    this.data.getInvestFinanPara().subscribe(data => {
      this.intro = data["data"][0].description;
    });
  }

  objectLength(obj) {
    var result = 0;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        result++;
      }
    }
    return result;
  }

}

<app-intro-tab-paragraph headerTitle="Our Board" text="{{ introP }}"></app-intro-tab-paragraph>

<ngx-slick-carousel class="carouselB" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of Members" class="slide">
        <a [routerLink]="['/team-details', slide.full_name]" attr.aria-label="More information on {{slide.full_name}}">
            <img src="{{ slide.image_thumbnail }}" attr.alt="{{slide.full_name}}" width="100%">
            <div class="textHolder">
                <p class="personName">{{ slide.full_name }}</p>
                <p class="personTitle">{{ slide.position }}</p>
            </div>
        </a>
    </div>
</ngx-slick-carousel>
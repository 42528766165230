<app-heading-holder headerTitle="Individual Investor" desc="{{intro}}"></app-heading-holder>

<div class="productListContainer">
    <div class="filterHolder">
        <label id="example-radio-group-label">What is your risk profile?</label>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedProfile" (change)="changeProfile(selectedProfile)">
            <p class="riskDesc">Moderate</p>
            <div class="radioHolder">
                <mat-radio-button class="example-radio-button" *ngFor="let profile of profiles" [value]="profile">
                </mat-radio-button>
            </div>
            <p class="riskDesc red">Aggressive</p>
        </mat-radio-group>
    </div>
    <div class="equalHWrap eqWrap" [@listAnimation]="ProductDetails">
        <div class=" equalHW eq" *ngFor="let Prod of ProductDetails">
            <div class="indivContainer">
                <h6>{{Prod.name}}</h6>
                <p>{{Prod.summary}}</p>
                <p class="risk"><b>Risk profile: </b>{{Prod.risk_profile}}</p>
                <p *ngIf="Prod.minimum_amount"><b>Minimum investment amount </b></p>
                <p [innerHTML]="Prod.minimum_amount"></p>
                <a class="investBtn" [routerLink]="['/offer/forms']">INVEST NOW</a> 
                <div class="downloadHolder" *ngIf="Prod.factsheet_doc_path_url">
                    <i class="fa fa-download"></i>
                    <a target="_blank" class="downloadBtn" href="{{ Prod.factsheet_doc_path_url }}">Download fact sheet</a>
                </div>
            </div>
        </div>
    </div>
</div>
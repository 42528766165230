import { Component, OnInit } from '@angular/core';
import { DataService } from './../../data.service';

@Component({
  selector: 'app-invest-holder',
  templateUrl: './invest-holder.component.html',
  styleUrls: ['./invest-holder.component.css']
})
export class InvestHolderComponent implements OnInit {
  introP: Object;
  introSubP:Object;
  
  constructor(private data: DataService) { }

  ngOnInit(): void {
    this.data.getResponsibleInvestingParagraph().subscribe(data => {
      this.introP =data['data'][0].description;
      this.introSubP =data['data'][0].title;
    }
  ); 
   
  }

}

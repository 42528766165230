<app-main-banner headerTitle="Responsible Investing" showBGDark="showBGDark" subMainTitle="{{introSubMP}}" subTitle="{{introMP}}" [styles]="{backgroundColor: '#9E002F'}"></app-main-banner>

<div class="content adjust-padding-bottom">
    <div class="contentContainer responsibleInvestingTabs">        
        <div class="miniHeader">
            <ul class="miniHeaderDesk">
                <li><a routerLink="/responsible-investing/proxy-voting" routerLinkActive="active-link" aria-label="Further details on our proxy voting results">Proxy Voting Results</a></li>
                <li><a routerLink="/responsible-investing/governance" routerLinkActive="active-link" aria-label="Further details on our governance procedures">Governance</a></li>
                <li><a routerLink="/responsible-investing/shariah" routerLinkActive="active-link" aria-label="Further details on our shariah products">Shari'ah</a></li>
                <li class="active"><a routerLink="/responsible-investing/invest-for-good" routerLinkActive="active-link" aria-label="How we invest back into society">Invest for good ®</a></li>
            </ul>
            <ul class="miniHeaderMobile">
                <li class="active"><a routerLink="/responsible-investing/invest-for-good" routerLinkActive="active-link" aria-label="How we invest back into society">Invest for good ®</a></li>
                <li><a routerLink="/responsible-investing/shariah" routerLinkActive="active-link" aria-label="Further details on our shariah products">Shari'ah</a></li>
                <li><a routerLink="/responsible-investing/governance" routerLinkActive="active-link" aria-label="Further details on our governance procedures">Governance</a></li>
                <li><a routerLink="/responsible-investing/proxy-voting" routerLinkActive="active-link" aria-label="Further details on our proxy voting results">Proxy Voting Results</a></li>
            </ul>
        </div>
    </div>
</div>

<div class="contain">
    <div class="containInner">
        <app-intro-tab-paragraph headerTitle="Invest for Good ®" text="{{introP}}">
        </app-intro-tab-paragraph>
    </div>
</div>

<div class="investHold">
    <div class="bannerCoverImg bannerCoverImg-Invetsments"></div>  
    <div class="investInfoBlock">
        <div class="investInfoBlockInner redBG">
            <h2>Investments</h2>
            <p [innerHTML]="InvestmentPara"></p>
            <p class="addOnPara">To view our ESG policy please <a class="insidePlink" routerLink="/responsible-investing/governance">click here</a>.</p>
        </div>
    </div>  
</div>

<div class="investHold">
    <div class="bannerCoverImg bannerCoverImg-Transformation"></div> 
    <div class="investInfoBlock">
        <div class="investInfoBlockInner darkBlueBG">
            <h2>Transformation</h2>
            <p [innerHTML]="TransformationPara"></p>
        </div>
    </div>
</div>

<div class="investHold">
    <div class="bannerCoverImg bannerCoverImg-CSI"></div> 
    <div class="investInfoBlock">
        <div class="investInfoBlockInner lightBlueBG">
            <h2>CSI</h2>
            <div class="floatRightBlock">
                <p [innerHTML]="CSIPara"></p>
            </div>
        </div>
    </div>
</div> 

<div class="investHold">
    <div class="bannerCoverImg bannerCoverImg-Academy"></div>
    <div class="investInfoBlock">
        <div class="investInfoBlockInner lightBlueBG">
            <h2>Mentorship Programme</h2>
            <p [innerHTML]="AcademyPara"></p>
        </div>
    </div>
</div>
<app-intro-tab-paragraph headerTitle="Our Journey" text="{{introP}}"></app-intro-tab-paragraph>
<div class="milestonesHolder">
    <h2>Milestones</h2>
    <div class="milestonesContainer">
        <div class="containerMilestones">
            <div *ngFor='let value of ourJourney; index as i'>
                <div *ngIf='i % 2 != 0'>
                    <div class="milestonesContainerInd showLeft">
                        <div class="leftMilestoneContainer">
                            <p class="year">{{value.year}}</p>
                            <div class="line"></div>
                            <p class="desc">{{value.description}}</p>
                        </div>
                        <div class="middleMilestoneContainer">
                            <div class="numberHolder numberContainter">    
                                <span>{{i+1}}</span>
                            </div>
                            <div class="horiLine"></div>
                        </div>                   
                    </div>
                </div>
                <div *ngIf='i % 2 == 0'>                   
                    <div class="milestonesContainerInd showRight">  
                        <div class="leftMilestoneContainer empty">
                        </div>                          
                        <div class="middleMilestoneContainer">
                            <div class="numberHolder numberContainter">    
                                <span>{{i+1}}</span>
                            </div>
                            <div class="horiLine"></div>
                        </div> 
                        <div class="rightMilestoneContainer">
                            <p class="year">{{value.year}}</p>
                            <div class="line"></div>
                            <p class="desc">{{value.description}}</p>
                        </div>                
                    </div>                    
                </div> 
            </div>
        </div>

    </div>

</div>
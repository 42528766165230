import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DataService } from '../../data.service';
import {animate, state, keyframes, query, stagger,style,transition, trigger} from '@angular/animations';

@Component({
 
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css']
})

export class ProductsListComponent implements OnInit {
  title = '';
  desc = '';
  selected = '';
  aboutP: Object;
  public ProductHeaderBU: any;
  ProductsBU: Object;
  header;
  status:boolean= false;
  nameSelected= "All";
  shakeIt = false;

  public ProductDetails: object = [];

  constructor(private data: DataService, private listNames: DataService, private listProducts: DataService) {
    this.getProducts();
  }
  getProducts() {
    return this.ProductHeaderBU;
  }

  ngOnInit(): void {
    this.data.getProductDesc().subscribe(data => {
      this.aboutP = data['data'][0].description;
    });

    this.listProducts.getAllProducts().subscribe(listProducts => {
      this.ProductsBU = listProducts['data'];
      this.ProductDetails = listProducts['data'];
    });

    this.listNames.getInvestProduct().subscribe(listNames => {
      this.ProductHeaderBU = listNames['data'];
    });
  }


  clickEvent(){
    this.status = !this.status;       
}

filterItem(name){
  
  this.status = !this.status;  
  if (name == "Shari"){
    name = "Shari'ah";
  }
  this.nameSelected = name; 

  var count = 0;
  this.ProductDetails = [];
  if (name == "All"){
    this.ProductDetails = this.ProductsBU;
  }else{
    for (var k = 0; k < this.objectLength(this.ProductsBU); k++) {
     if (this.ProductsBU[k].investment_type.includes(name)) {
        this.ProductDetails[count] = this.ProductsBU[k];
        count++;
      }
    }
  }

  this.title = name;
  if (this.title == "All"){
    this.desc = "";
  }else{
    for (var k = 0; k < this.objectLength(this.ProductHeaderBU); k++) {
      if (this.ProductHeaderBU[k].name === name) {
        this.desc = this.ProductHeaderBU[k].description;
      }
    }
  }
  this.shakeDialog();

  return this.ProductDetails;
  

}

shakeDialog() {
  this.shakeIt = true;
  setTimeout((arg) => {
    this.shakeIt = false;
  },
  2000);
}

  SearchProduct(name: any) {
    var count = 0;
    this.ProductDetails = [];
    if (name == "All"){
      this.ProductDetails = this.ProductsBU;
    }else{
      for (var k = 0; k < this.objectLength(this.ProductsBU); k++) {
        if (this.ProductsBU[k].investment_type.includes(name)) {
          this.ProductDetails[count] = this.ProductsBU[k];
          count++;
        }
      }
    }
    
  
    this.title = name;
    if (this.title == "All"){
      this.desc = "";
    }else{
      for (var k = 0; k < this.objectLength(this.ProductHeaderBU); k++) {
        if (this.ProductHeaderBU[k].name === name) {
          this.desc = this.ProductHeaderBU[k].description;
        }
      }
    }
        
    return this.ProductDetails;
  }

  objectLength(obj) {
    var result = 0;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        result++;
      }
    }
    return result;
  }

    wwoProductLink(arraylist){
        if(arraylist.length === 1){
            if(arraylist.indexOf("Institutional") > -1){
                return "/invest-institutional";
            }
            if(arraylist.indexOf("Financial Adviser") > -1){
                return "/invest-financial";
            }
            if(arraylist.indexOf("Individual Investor") > -1){
                return "/invest-individual";
            }
        }else{
            if(arraylist.indexOf("Individual Investor") > -1){
                return "/invest-individual";
            }else if(arraylist.indexOf("Financial Adviser") > -1){
                return "/invest-financial";
            }else if(arraylist.indexOf("Institutional") > -1){
                return "/invest-institutional";
            }          
        }
    }   

}

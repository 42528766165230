import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invest-individual',
  templateUrl: './invest-individual.component.html',
  styleUrls: ['./invest-individual.component.css']
})
export class InvestIndividualComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-our-board',
  templateUrl: './our-board.component.html',
  styleUrls: ['./our-board.component.css']
})
export class OurBoardComponent implements OnInit {
  introP: Object;
  Members: Object;
  title = 'ngSlick';
 
  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "dots":true,
    "infinite": false,
    "responsive": [
          {
            "breakpoint": 1000,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1
            }
          },
          {
            "breakpoint": 767,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
  };
   
  slickInit(e) {
  }
  
  breakpoint(e) {
  }
  
  afterChange(e) {
  }
  
  beforeChange(e) {
  }
 
  constructor(private data: DataService,private memData: DataService) { }
  ngOnInit() {
    this.data.getOurBoardParagraph().subscribe(data => {
        this.introP = data['data'][0].description;
      }
    ); 

    this.memData.getBoardMembers().subscribe(memData => {
      this.Members = memData['data'].map((val)=>{     
        return {
          "full_name": val.fullname,
          "position": val.position,
          "linkedin_url": val.linkedinUrl,
          "main_image":  val.main_image,
          "image_thumbnail":  val.image_thumbnail,
          "tag": val.tag,
          "key_facts": val.key_facts,
          "biography": val.biography,
          "qualifications": val.qualifications
        }             
      })
    }
  ); 
  }

}

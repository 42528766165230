import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.css']
})
export class PressComponent implements OnInit {
  bits = [
    {
      title: 'Impact Investing: Five Trends Advisors Need to Know',
      date: '02.03.2020',
      image: '../../../assets/img/news1.png',
      link: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
      desc: 'Sentio cofounder, Mohamed Mayet, provides insights on the role of philanthropy as a ‘jump in’ point for impact inves…'
    },
    {
      title: 'Impact Investing: Five Trends Advisors Need to Know',
      date: '02.03.2020',
      image: '../../../assets/img/news2.png',
      link: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
      desc: 'Sentio cofounder, Mohamed Mayet, provides insights on the role of philanthropy as a ‘jump in’ point for impact inves…'
    },
    {
      title: 'Impact Investing: Five Trends Advisors Need to Know',
      date: '02.03.2020',
      image: '../../../assets/img/news3.png',
      link: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
      desc: 'Sentio cofounder, Mohamed Mayet, provides insights on the role of philanthropy as a ‘jump in’ point for impact inves…'
    },
    {
      title: 'Impact Investing: Five Trends Advisors Need to Know',
      date: '02.03.2020',
      image: '../../../assets/img/news4.png',
      link: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg',
      desc: 'Sentio cofounder, Mohamed Mayet, provides insights on the role of philanthropy as a ‘jump in’ point for impact inves…'
    }
    
  ];
  constructor(private list: DataService) { }
  hold: Object;
  ngOnInit(){
  //   this.list.getPressList().subscribe(list => {
  //    this.hold = list['data'];
  //   }
  // ); 
    
  }

}

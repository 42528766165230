<app-heading-holder headerTitle="Financial Advisor" desc="{{intro}}"></app-heading-holder>

<div class="productListContainer" [@listAnimation]="ProductDetails">
    <div class="equalHWrap eqWrap">
        <div class=" equalHW eq" *ngFor="let Prod of ProductDetails">
            <div class="indivContainer">
                <h6>{{Prod.name}}</h6>
                <p><b>Fund category: </b>{{Prod.asisa_category}}</p>
                <p><b>Benchmark: </b>{{Prod.benchmark}}</p>
                <p><b>LISPs: </b>{{Prod.lisp}}</p>
                <p><b>Risk profile: </b>{{Prod.risk_profile}}</p>
                <a class="investBtn" [routerLink]="['/offer/forms']">INVEST NOW</a> 
                <div class="downloadHolder" *ngIf="Prod.factsheet_doc_path_url">
                    <i class="fa fa-download"></i>
                    <a target="_blank" class="downloadBtn" href="{{ Prod.factsheet_doc_path_url }}">Download fact sheet</a>
                </div>
            </div>
        </div>
    </div>
</div>
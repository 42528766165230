import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-insights-sub',
  templateUrl: './insights-sub.component.html',
  styleUrls: ['./insights-sub.component.css'],
  inputs: ['type']
})
export class InsightsSubComponent implements OnInit {
  
 constructor(private list: DataService) { }
  hold: Object;
  count: number = 0;
  temp:[[]];
  slides: any = [[]];
  slidesHold: any = [];
  insights: Object;
  public type : string

  ngOnInit(){
    this.list.getInsights().subscribe(list => {
      this.insights = list['data'].map((val)=>{  
        return {
          "id": val.id,
          "author": val.author,
          "date": val.date,
          "description": val.description,
          "headline": val.headline,
          "thumbnail": val.thumbnail,
          "type": val.type,
          "videoUrl": val.videoUrl,
          "displayInShariahSection":val.displayInShariahSection,
          "download":val.download,
          "assetsPayload":val.assetsPayload       
        }             
      }).filter((val) =>{
        return val.type==this.type;
      })
    }
  ); 
  
  }

  chunk(arr, chunkSize) {
    let R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataService } from '../../data.service';

import { team } from '../../team';


@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.css']
})
export class TeamDetailsComponent implements OnInit {
  member: any;
  count: number;
  item: any;
  index: any;
  len: any;

  Members: Object;
  fullName: string;
  position: string;
  linkedin_url: string;
  twitter_url: string;
  image_main: string;
  key_facts: string;
  biography: string;
  qualifications: string;
  tag: string;
  MembersNav: Object;
  MembersNavInv: Object;
  MembersNavBus: Object;
  navLen: number;
  currIndex: number;
  viewNext: boolean = true;
  viewPrev: boolean = true;
  prevIndex: number;
  nextIndex: any;
  showLinkd:Boolean = true;
  showTwitter:Boolean = true;

  nextIndexURL: number;
  prevIndexURL: number;
  returnURL: string;
  returnURLText: string;
  redirectText:string
  redirectURL:string;
  showRedirect:Boolean= false;


  constructor(private route: ActivatedRoute, private router: Router, private data: DataService, private memData: DataService, private memDataNew: DataService, private memDataBus: DataService) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
    });
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.member = this.route.snapshot.paramMap.get('fullname');     
      this.data.getMember(this.member).subscribe(data => {
        this.fullName = data['data'][0].fullname;
        this.position = data['data'][0].position;
        this.linkedin_url = data['data'][0].linkedin_url;
        if (this.linkedin_url== null){
          this.showLinkd = false;
        }else{
          this.showLinkd = true;
        }
        this.twitter_url = data['data'][0].twitter_url;
        if (this.twitter_url== null){
          this.showTwitter = false;
        }else{
          this.showTwitter = true;
        }

        this.image_main = data['data'][0].main_image;

        this.key_facts = data['data'][0].key_facts;
        this.biography = data['data'][0].biography;
        this.qualifications = data['data'][0].qualifications;
        this.tag = data['data'][0].member_tag;
        switch (this.tag) {
          case "Board": {
            this.showRedirect = false;
            this.returnURL = '/about-our-board';
            this.returnURLText = 'Back to Board';
            this.memData.getBoardMembers().subscribe(memData => {
              this.MembersNav = memData['data'];
              this.navLen = this.objectLength(this.MembersNav);

              for (var i = 0; i < this.navLen; i++) {
                if (this.member == this.MembersNav[i].id) {
                  if (i == 0) {
                    this.nextIndexURL = this.MembersNav[i + 1].id;
                    this.prevIndexURL = this.MembersNav[0].id;
                    this.viewPrev = false;
                    this.viewNext = true;
                  } else {
                    if (i == (this.objectLength(this.MembersNav) - 1)) {
                      this.nextIndexURL = this.MembersNav[0].id;
                      this.prevIndexURL = this.MembersNav[i - 1].id;
                      this.viewPrev = true;
                      this.viewNext = false;
                    } else {
                      this.nextIndexURL = this.MembersNav[i + 1].id;
                      this.prevIndexURL = this.MembersNav[i - 1].id;
                      this.viewPrev = true;
                      this.viewNext = true;
                    }
                  }
                }
              }
            }
            );
            break;
          }
          case "Investment": {
            this.showRedirect = true;
            this.returnURL = '/about-our-team';
            this.returnURLText = 'Back to Team';
            this.redirectText = 'View Business Team';
            this.memDataNew.getBusinessMembers().subscribe(memDataNew => {
              this.redirectURL =memDataNew['data'][0].id;
            });
            this.memDataNew.getInvestmentMembers().subscribe(memDataNew => {
              
              this.MembersNavInv = memDataNew['data'];
              this.navLen = this.objectLength(this.MembersNavInv);

              for (var i = 0; i < this.navLen; i++) {
                if (this.member == this.MembersNavInv[i].id) {
                  if (i == 0) {
                    this.nextIndexURL = this.MembersNavInv[i + 1].id;
                    this.prevIndexURL = this.MembersNavInv[0].id;
                    this.viewPrev = false;
                    this.viewNext = true;
                  } else {
                    if (i == (this.objectLength(this.MembersNavInv) - 1)) {
                      this.nextIndexURL = this.MembersNavInv[0].id;
                      this.prevIndexURL = this.MembersNavInv[i - 1].id;
                      this.viewPrev = true;
                      this.viewNext = false;
                    } else {
                      this.nextIndexURL = this.MembersNavInv[i + 1].id;
                      this.prevIndexURL = this.MembersNavInv[i - 1].id;
                      this.viewPrev = true;
                      this.viewNext = true;
                    }
                  }
                }
              }
            }
            );
            break;
          }
          case "Business": {
            this.showRedirect = true;
            this.returnURL = '/about-our-team';
            this.returnURLText = 'Back to Team';
            this.redirectText = 'View Investment Team';
            this.memDataNew.getInvestmentMembers().subscribe(memDataNew => {
              this.redirectURL =memDataNew['data'][0].id;
            });
            this.memDataBus.getBusinessMembers().subscribe(memDataBus => {
              this.MembersNavBus = memDataBus['data'];
              this.navLen = this.objectLength(this.MembersNavBus);

              for (var i = 0; i < this.navLen; i++) {
                if (this.member == this.MembersNavBus[i].id) {
                  if (i == 0) {
                    this.nextIndexURL = this.MembersNavBus[i + 1].id;
                    this.prevIndexURL = this.MembersNavBus[0].id;
                    this.viewPrev = false;
                    this.viewNext = true;
                  } else {
                    if (i == (this.objectLength(this.MembersNavBus) - 1)) {
                      this.nextIndexURL = this.MembersNavBus[0].id;
                      this.prevIndexURL = this.MembersNavBus[i - 1].id;
                      this.viewPrev = true;
                      this.viewNext = false;
                    } else {
                      this.nextIndexURL = this.MembersNavBus[i + 1].id;
                      this.prevIndexURL = this.MembersNavBus[i - 1].id;
                      this.viewPrev = true;
                      this.viewNext = true;
                    }
                  }
                }
              }
            }
            );
            break;
          }
        }

      }
      );
      
     
    });

  }



  objectLength(obj) {
    var result = 0;
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        result++;
      }
    }
    return result;
  }
}

<app-about></app-about>
<div class="miniHeader">
    <ul class="miniHeaderDesk">
        <li><a routerLink="/about-our-journey" routerLinkActive="active-link" aria-label="How did we get here">Our Journey</a></li>
        <li><a routerLink="/about-our-team" routerLinkActive="active-link" aria-label="More about our different teams">Our team</a></li>
        <li class="active"><a routerLink="/about-our-board" routerLinkActive="active-link" aria-label="See who is on our board">Our Board</a></li>
        <li><a routerLink="/about-our-philosophy" routerLinkActive="active-link" aria-label="Read more about who we are">Our philosophy</a></li>
    </ul>

    <ul class="miniHeaderMobile">
        <li><a routerLink="/about-our-philosophy" routerLinkActive="active-link" aria-label="Read more about who we are">Our philosophy</a></li>
        <li class="active"><a routerLink="/about-our-board" routerLinkActive="active-link" aria-label="See who is on our board">Our Board</a></li>
        <li><a routerLink="/about-our-team" routerLinkActive="active-link" aria-label="More about our different teams">Our team</a></li>
        <li><a routerLink="/about-our-journey" routerLinkActive="active-link" aria-label="How did we get here">Our Journey</a></li>
    </ul>
</div>

<div class="content">
    <div class="contentContainer">
        <app-our-board></app-our-board>
    </div>
</div>
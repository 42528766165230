<app-invest></app-invest>
<div class="miniHeader">
    <ul class="miniHeaderDesk">
        <li class="active"><a routerLink="/invest-institutional" routerLinkActive="active-link" aria-label="Explore our institutional investor products">Institutional</a></li>
        <li><a routerLink="/invest-financial" routerLinkActive="active-link" aria-label="Explore our independent financial advisor investor products">Financial Advisor</a></li>
        <li><a routerLink="/invest-individual" routerLinkActive="active-link" aria-label="Explore our individual investor products">Individual Investor</a></li>
    </ul>
    <ul class="miniHeaderMobile">
        <li><a routerLink="/invest-individual" routerLinkActive="active-link" aria-label="Explore our individual investor products">Individual Investor</a></li>
        <li><a routerLink="/invest-financial" routerLinkActive="active-link" aria-label="Explore our independent financial advisor investor products">Financial Advisor</a></li>
        <li class="active"><a routerLink="/invest-institutional" routerLinkActive="active-link" aria-label="Explore our institutional investor products">Institutional</a></li>
    </ul>
</div>


<div class="content">
    <div class="contentContainer">
        <app-institutional></app-institutional>
    </div>
</div>